import { Text as Cmpt } from "@/components/ui/text";

function Text({type, variant, ...props }) {
	return <Cmpt 
				type={type}
				variant={variant}
				{...props}
			/>;
}

export default Text;
